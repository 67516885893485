<template>
  <a-drawer
    placement="left"
    :visible="drawerVisible"
    @close="$emit('close-drawer')"
    width="72vw"
    :get-container="false"
    :wrap-style="{ position: 'absolute', left: '-32px' }"
    wrapClassName="monetize-drawer"
    :mask="false"
  >
    <div class="collaborate-drawer">
      <h1>Monetize your Article</h1>
      <a-row>
        <a-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          class="collaborate-drawer__left"
        >
          <div class="search">
            <i class="icon icon-search"></i>
            <form action="">
              <input type="text" placeholder="Search Locations here" />
            </form>
          </div>
          <div class="map">
            <img src="../../../assets/images/mapp.png" alt="" />
          </div>
        </a-col>
        <a-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 11, offset: 1 }"
          :lg="{ span: 11, offset: 1 }"
          class="collaborate-drawer__right"
        >
          <div class="selet-fee">
            <a-select
              default-value="Select Patron Fee"
              style="width: 100%"
              @change="handleChange"
            >
              <a-select-option value="jack"> Jack </a-select-option>
              <a-select-option value="lucy"> Lucy </a-select-option>
              <a-select-option value="jack"> Jack </a-select-option>
              <a-select-option value="lucy"> Lucy </a-select-option>
            </a-select>
          </div>
          <h2>Select amount (USD)</h2>
          <div class="select-amount">
            <form action="">
              <input type="text" placeholder="$ Enter Amount" />
            </form>
            <div class="price">
              <a-checkbox @change="onChangeCheckbox"> </a-checkbox>
              <p>$ 1.0000</p>
            </div>
          </div>
          <h2>Select your target audience</h2>
          <div class="select-date">
            <p>Age:</p>
            <a-date-picker
              placeholder="Form"
              @change="onChange"
              style="z-index: 22222222222 !important"
            />
            <a-date-picker placeholder="To" @change="onChange" />
          </div>
          <div class="selet-gender">
            <p>Gender:</p>
            <a-select
              default-value="Select Gender"
              style="width: 100%"
              @change="handleChange"
            >
              <a-select-option value="Male"> Male </a-select-option>
              <a-select-option value="Femail"> Female </a-select-option>
            </a-select>
          </div>
          <button class="apply-btn">Apply Monetization</button>
        </a-col>
      </a-row>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: {
    drawerVisible: Boolean,
  },
  methods: {
    onChange(date, dateString) {
      console.log(date, dateString);
    },
    onChangeCheckbox(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss">
@include reset-css;
.monetize-drawer {
  // width: 77% !important;
  @include respond(phone-x-small) {
    left: -12px !important;
  }
  .ant-drawer-content-wrapper {
    left: -10px !important;
  }
  .ant-drawer-wrapper-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 2rem;
    .ant-drawer-header-no-title {
      .ant-drawer-close {
        right: 2rem;
        top: 2rem;
      }
    }
  }

  .ant-drawer-wrapper-body::-webkit-scrollbar {
    width: 0.5rem;
  }

  .ant-drawer-wrapper-body::-webkit-scrollbar-track {
    background-color: #c8c8c87d;
    border-radius: 0.8rem;
  }

  .ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }

  .collaborate-drawer {
    h1 {
      font-size: 2.5rem;
      font-family: $font-secondary-bold;
    }
    &__left {
      .search {
        display: flex;
        align-items: center;
        margin: 2rem 0rem;
        border-bottom: 0.24rem solid #70707036;
        width: 100%;
        .icon {
          margin-right: 1.6rem;
          margin-left: 0.5rem;
          font-size: 2rem;
        }
        form {
          input {
            border: none;
            outline: none;
            background-color: transparent;
            padding: 0.8rem 0rem;
            font-family: $font-primary;
            font-size: 1.6rem;
          }
          input::placeholder {
            opacity: 0.5;
          }
        }
      }
      .map {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__right {
      padding-top: 8rem;
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: transparent;
        box-shadow: none;
        border: none;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        box-shadow: none;
      }
      .ant-input:focus {
        box-shadow: none;
      }
      .selet-fee {
        .ant-select-selection-item {
          opacity: 0.8;
          font-size: 1.6rem;
          height: 32px;
          font-family: $font-secondary;
          color: $color-black;
        }
      }
      h2 {
        font-size: 1.9rem;
        font-family: $font-secondary-bold;
        margin: 2.5rem 0rem;
      }
      .select-amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        form {
          width: 100%;
          input {
            border: 1px solid rgba(1, 30, 41, 0.1);
            outline: none;
            background-color: transparent;
            padding: 1rem;
            height: 32px;
            background-color: $color-white;
            font-family: $font-primary;
            font-size: 1.6rem;
            width: 100%;
          }
          input::placeholder {
            opacity: 0.5;
          }
        }
        .price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 15rem;
          margin-left: 1rem;
          padding: 1rem;
          height: 32px;
          background-color: $color-white;
          p {
            margin-bottom: 0;
            font-size: 1.4rem;
            font-family: $font-secondary;
          }
          .ant-checkbox-inner {
            border: 0.24rem solid #1919194d;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $color-primary;
            border-color: $color-primary;
          }
          .ant-checkbox-checked .ant-checkbox-inner:hover {
            border-color: $color-primary;
          }
        }
      }

      .select-date {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin-bottom: 0;
          font-size: 1.8rem;
          font-family: $font-secondary;
        }

        .ant-calendar-picker-input {
          width: 14rem;
          border: none;
          font-size: 1.6rem;
          height: 32px;
          font-family: $font-secondary;
        }
        .ant-calendar-picker-input::placeholder {
          color: $color-black;
          opacity: 0.8;
        }
      }
      .selet-gender {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2.2rem 0rem;
        p {
          margin-bottom: 0;
          font-size: 1.8rem;
          font-family: $font-secondary;
          margin-right: 1rem;
        }
        .ant-select-selection-item {
          opacity: 0.8;
          font-size: 1.6rem;
          height: 32px;
          font-family: $font-secondary;
          color: $color-black;
        }
      }
      .apply-btn {
        background-color: $color-primary;
        color: $color-white;
        font-family: $font-primary-semibold;
        font-size: 1.4rem;
        border: none;
        width: 100%;
        height: 5.5rem;
        margin-top: 1rem;
      }
      .apply-btn:hover {
        opacity: 0.9;
      }
    }
  }
}
.ant-calendar-picker-container {
  z-index: 22222222222 !important;
}
</style>

<template>
  <a-drawer
    placement="left"
    :visible="drawerVisible"
    @close="$emit('close-drawer')"
    width="75vw"
    :get-container="false"
    :wrap-style="{ position: 'absolute', left: '-32px' }"
    wrapClassName="research-drawer"
    :mask="false"
  >
    <div class="research-drawer">
      <h1>Glocal heatmap</h1>
      <div class="links">
        <div class="links__left">
          <p>
            Curate your analysis by illuminating portions of the news and
            setting custom filters. And, when you’re ready, create exportable
            data visualizations that illustrate your key findings.
          </p>
        </div>

        <div class="links__right">
          <li
            class="nav-link"
            @click="selectLink('social')"
            :class="{ active: isSelected('social') }"
          >
            Social News
          </li>
          <li
            class="nav-link"
            @click="selectLink('industry')"
            :class="{ active: isSelected('industry') }"
          >
            Industry Insights
          </li>
          <li
            class="nav-link"
            @click="selectLink('scholar')"
            :class="{ active: isSelected('scholar') }"
          >
            Scholars
          </li>
        </div>
      </div>
      <a-row :gutter="[16, 8]" class="maps">
        <a-col :xs="24" :sm="24" :md="15" :lg="15" class="maps__black">
          <picture>
            <source
              srcset="../../../assets/images/magic.webp"
              media="(max-width: 48em)"
            />
            <img srcset="../../../assets/images/magic@2x.webp" />
          </picture>
        </a-col>
        <a-col :xs="24" :sm="24" :md="9" :lg="9" class="maps__white">
          <img src="../../../assets/images/white-map.png" alt="" />
        </a-col>
      </a-row>
      <a-row :gutter="[18, 18]" class="research-article">
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
          <div class="research-article__card">
            <div class="card-header">
              <i class="icon icon-open_new-tab"></i>
              <div class="article-img">
                <img src="../../../assets/images/daily-mail@2x.webp" alt="" />
              </div>
              <p>
                Case study Houses: The creation of the house and other things
                that
              </p>
            </div>
            <div class="card-body">
              <p>
                The passage is attributed to an unknown typesetter in the 15th
                century who is thought to have.
              </p>
            </div>
            <div class="card-footer">
              <p>ASIA · Fri 7:24 5:08 PM | Robert Forst, Business CNN</p>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
          <div class="research-article__card">
            <div class="card-header">
              <i class="icon icon-open_new-tab"></i>
              <div class="article-img">
                <img src="../../../assets/images/daily-mail@2x.webp" alt="" />
              </div>
              <p>
                Case study Houses: The creation of the house and other things
                that
              </p>
            </div>
            <div class="card-body">
              <p>
                The passage is attributed to an unknown typesetter in the 15th
                century who is thought to have.
              </p>
            </div>
            <div class="card-footer">
              <p>ASIA · Fri 7:24 5:08 PM | Robert Forst, Business CNN</p>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8">
          <div class="research-article__card">
            <div class="card-header">
              <i class="icon icon-open_new-tab"></i>
              <div class="article-img">
                <img src="../../../assets/images/daily-mail@2x.webp" alt="" />
              </div>
              <p>
                Case study Houses: The creation of the house and other things
                that
              </p>
            </div>
            <div class="card-body">
              <p>
                The passage is attributed to an unknown typesetter in the 15th
                century who is thought to have.
              </p>
            </div>
            <div class="card-footer">
              <p>ASIA · Fri 7:24 5:08 PM | Robert Forst, Business CNN</p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: {
    drawerVisible: Boolean,
  },
  data() {
    return {
      selectedLink: "social",
    };
  },
  methods: {
    selectLink(link) {
      this.selectedLink = link;
    },
    isSelected(link) {
      return this.selectedLink == link;
    },
  },
};
</script>
<style lang="scss">
@include reset-css;

.research-drawer {
  @include respond(phone-x-small) {
    left: -12px !important;
  }
  .ant-drawer-wrapper-body {
    overflow-x: hidden;
    padding-right: 2rem !important;

    // @include respond(tab-port) {
    //   padding-right: 2rem !important;
    // }
  }
  .ant-drawer-content-wrapper {
    left: -10px !important;
  }
  .ant-drawer-wrapper-body::-webkit-scrollbar {
    width: 0.5rem;
  }

  .ant-drawer-wrapper-body::-webkit-scrollbar-track {
    background-color: #c8c8c87d;
    border-radius: 0.8rem;
  }

  .ant-drawer-wrapper-body::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 0.8rem;
    height: 5rem;
  }

  h1 {
    font-size: 2.5rem;
    font-family: $font-secondary-bold;
    margin-bottom: 0;
  }
  .links {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    @include respond(laptop-small) {
      flex-direction: column;
    }
    &__left {
      font-size: 1.6rem;
      font-family: $font-primary;
      opacity: 0.5;
      margin-bottom: 0;
      width: 100%;
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      @include respond(tab-port) {
        justify-content: flex-start;
      }
      @include respond(phone-large) {
        justify-content: flex-start;
      }
      @include respond(phone-x-small) {
        justify-content: flex-start;
      }

      .nav-link {
        list-style: none;
        margin-right: 3rem;
        font-size: 1.6rem;
        color: $color-black;
        font-family: $font-secondary;
        position: relative;
        opacity: 0.8;
        cursor: pointer;
      }
      .active {
        font-family: $font-secondary-bold;
      }
      .active::after {
        content: "";
        width: 2.6rem;
        height: 0.4rem;
        background-color: $color-primary;
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        opacity: 1;
      }
    }
  }
  .maps {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    &__black {
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__white {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .research-article {
    margin: 5rem 0rem;
    &__card {
      // width: 42rem;
      background-color: $color-white;
      padding: 1.3rem 2.5rem;
      position: relative;
      .card-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .icon {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 2rem;
          opacity: 0.4;
        }
        p {
          font-size: 1.7rem;
          font-family: $font-primary-bold;
          margin-bottom: 0;
          width: 25.5rem;
          line-height: 2.3rem;
        }
        .article-img {
          width: 9rem;
          height: 7rem;
          margin-right: 1.5rem;
          // margin-left: -2rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .card-body {
        margin-top: 0.6rem;
        margin-bottom: 1rem;
        p {
          font-size: 1.3rem;
          font-family: $font-primary;
          margin-bottom: 0;
          opacity: 0.8;
        }
      }
      .card-footer {
        p {
          font-size: 1.4rem;
          font-family: $font-primary;
          margin-bottom: 0;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>

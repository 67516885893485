<template>
<a-drawer 
      :visible="drawerVisible"
      placement="left"
      width="55rem"
      :get-container="false"
      :wrap-style="{ position: 'absolute', left: '-30px' }"
      wrapClassName="seo-drawer"
      :mask="false"
      @close="$emit('close-drawer')"
      >
  <div class="seo-drawer-main">
    <div class="heading">
      <h1>Get found on Google</h1>
      <p>Boost your readers Traffic with Glocal SEO - Setup toolkit</p>
    </div>
    <div class="seo-info">
      <div class="content">
        <h4>Preview on Google.</h4>
        <div class="links">
          <h2>Glocal | Top trends for UX 2021 | Alejandro</h2>
          <a href="">www.glocal.com/alejandro/UXTrends2021</a>
        </div>
      </div>
      <div class="content">
        <h4>What's the URL slug (Last part of URL) for this page?</h4>
        <div class="slug">
          <p>/</p>
          <form action="">
            <input type="text" placeholder="UXtrends2021" />
          </form>
          <a href="">Go to URL</a>
        </div>
      </div>
      <div class="content">
        <h4>What's the page title tag (Title in search results)?</h4>
        <div class="title">
          <p>Glocal | Top trends for UX 2021 | Alejandro</p>
        </div>
      </div>
      <div class="content">
        <h4>What's the page meta description (Description for search)?</h4>
        <div class="title">
          <p>UX Top trends 2021 - Break through research by Alejandro.</p>
        </div>
      </div>
    </div>
    <div class="search-btn">
      <p>Let search engines index this page?</p>
      <a-switch :checked="checked" @change="changeState"/>
    </div>
    <button class="apply-seo-btn">Apply SEO Setup</button>
  </div>

  </a-drawer>
</template>
<script>
export default {
  props: {
    drawerVisible: Boolean
  },
  data(){
    return {
      checked: false
    }
  },
  methods:{
    changeState(){
      this.checked =!this.checked;
    }
  }
}
</script>
<style lang="scss">
.seo-drawer-main {
  @include reset-css;
  .heading {
    h1 {
      font-size: 2.5rem;
      font-family: $font-secondary-bold;
      margin-bottom: 0.3rem;
    }
    p {
      font-size: 1.6rem;
      font-family: $font-primary;
      opacity: 0.5;
    }
  }

  .seo-info {
    .content {
      padding: 2rem 0;
      h4 {
        font-size: 1.6rem;
        font-family: $font-primary;
        margin-bottom: 1.7rem;
      }
      .links {
        background-color: $color-white;
        padding: 1.5rem 3rem;
        h2 {
          font-size: 2rem;
          font-family: $font-secondary-bold;
          color: #3024b3;
          line-height: 2.3rem;
          cursor: pointer;
        }
        a {
          font-size: 1.6rem;
          font-family: $font-primary;
          color: #1f793c;
        }
      }
      .slug {
        display: flex;
        justify-content: center;
        align-items: center;
        form {
          input {
            width: 35rem;
            border: none;
            outline: none;
            padding: 1rem 2rem;
            margin: 0 1rem;
          }
          input::placeholder {
            font-size: 1.6rem;
            font-family: $font-primary;
          }
        }
        a {
          font-size: 1.6rem;
          font-family: $font-primary;
          text-decoration: $color-primary;
          color: $color-primary;
        }
        p {
          font-size: 1.6rem;
          font-family: $font-primary;
        }
      }
      .title {
        background-color: $color-white;
        padding: 1.5rem 3rem;
        p {
          font-size: 1.6rem;
          font-family: $font-primary;
        }
      }
    }
    & > .content:not(:last-child) {
      border-bottom: 0.24rem solid rgba(0, 0, 0, 0.075);
    }
  }

  .search-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    p {
      font-size: 1.6rem;
      font-family: $font-primary;
    }
    .ant-switch:focus {
      box-shadow: none;
    }
  }
  .apply-seo-btn {
    width: 100%;
    text-align: center;
    border: none;
    background-color: $color-primary;
    color: $color-white;
    font-size: 1.6rem;
    font-family: $font-primary-semibold;
    height: 5.5rem;
    margin-top: 3.2rem;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="main-body">
    <a-row>
      <a-col
        :xs="{ span: 24 }"
        :sm="{ span: 2 }"
        :md="{ span: 2 }"
        :lg="{ span: 2 }"
      >
        <div class="side-tools">
          <side-tools
            @show-research-drawer="showResearchDrawer = true"
            @show-seo-drawer="showSeoDrawer = true"
            @show-collaborate-drawer="showCollaborateDrawer = true"
          ></side-tools>
        </div>
      </a-col>
      <a-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 10, offset: 2 }"
        :md="{ span: 10, offset: 4 }"
        :lg="{ span: 10, offset: 6 }"
      >
        <div class="editor-section">
          <keep-alive>
            <component :is="active" ></component>
          </keep-alive>
        </div>
      </a-col>
      <a-col
        :xs="{ span: 2, offset: 21 }"
        :sm="{ span: 2, offset: 22 }"
        :md="{ span: 2, offset: 5 }"
        :lg="{ span: 2, offset: 3 }"
      >
      </a-col>
    </a-row>

    <research-drawer
      :drawer-visible="showResearchDrawer"
      @close-drawer="showResearchDrawer = false"
    ></research-drawer>

    <seo-drawer
      :drawer-visible="showSeoDrawer"
      @close-drawer="showSeoDrawer = false"
    ></seo-drawer>

    <collaborate-drawer
      :drawer-visible="showCollaborateDrawer"
      @close-drawer="showCollaborateDrawer = false"
    ></collaborate-drawer>
  </div>
</template>
<script>
import SideTools from "./SideTools.vue";
import TextEditor from "./TextEditor.vue";
import ResearchDrawer from "./ResearchDrawer.vue";
import CollaborateDrawer from "./CollaborateDrawer.vue";
import SeoDrawer from "./SeoDrawer.vue";

export default {
  components: {
    SideTools,
    TextEditor,
    CollaborateDrawer,
    ResearchDrawer,
    SeoDrawer,
  },
  data() {
    return {
      active: null,
      showCollaborateDrawer: false,
      showSeoDrawer: false,
      showResearchDrawer: false,
    };
  },
  mounted(){
    this.active = TextEditor;
  },
  methods: {},
  watch: {
    showSeoDrawer() {
      console.log(this.showSeoDrawer);
    },
  },
};
</script>

<style lang="scss">
@include reset-css;

.main-body {
  padding-top: 3.5rem !important;
  height: 85.8rem;
  position: relative;
  .seo-drawer {
    @include respond(phone-x-small) {
    left: -12px !important;
  }
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: 0 8px 25px -6px rgba(0, 0, 0, 0.15) !important;      
    }
    .ant-drawer-content-wrapper {
    left: -10px !important;
  }
    .ant-drawer-content {
      background-color: #f6f8ff !important;
      padding: 3rem 4rem;
    }
    .ant-drawer-close {
      top: 12px;
      right: 20px;
    }
  }
  .research-drawer {
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: 0 8px 25px -6px rgba(0, 0, 0, 0.15) !important;
    }
    .ant-drawer-content {
      background-color: #f6f8ff !important;
      padding: 3rem 2rem 3rem 4rem;
    }
    .ant-drawer-close {
      top: 12px;
      right: 20px;
      @include respond(phone-x-small){
        top: 0 !important;
        right: 10px !important;
      }
    }
  }
  .monetize-drawer {
    // .ant-drawer-content-wrapper {
    //   width: 100% !important;
    // }
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: 0 8px 25px -6px rgba(0, 0, 0, 0.15) !important;
    }
    .ant-drawer-content {
      background-color: #f6f8ff !important;
      padding: 3rem 2rem 3rem 4rem;
    }
  }
}
.side-tools {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @include respond(phone-x-small) {
    flex-direction: inherit;
    justify-content: space-between;
  }
}
.addbutton {
  position: relative;
  bottom: 0;
}
</style>

<template>
  <div class="text-area-main">
    <div class="text-area">
      <keep-alive>
          <component :is="active" ref="tiptap" ></component>
      </keep-alive>
      <input
        class="search-video-input"
        v-if="showVideoSearchInput"
        type="text"
        placeholder="Paste a Youtube, Vimeo or other video link and press Enter"
        @keyup.enter="searchVideo"
        v-model="video"
      />
      <input
        class="search-unsplash-input"
        v-if="searchUnsplash"
        type="text"
        placeholder="Type keywords to search Unsplash and press Enter"
        @keyup.enter="searchWithUnsplash"
        v-model="searchQuery"
      />
      <div v-if="currentPage" class="results" ref="results">
        <div class="buttons">
          <button ref="prev-btn" class="btn prev-btn" disabled @click="goTo(-1)">Previous</button>
          <span>{{this.totalResults}} results</span>
          <button ref="next-btn" class="btn next-btn" @click="goTo(1)">Next</button>
        </div>
        <div class="images">
          <div v-for="image in unsplashImages" :key="image" :img-src="image"
            @click="selectImage(image.url)">
            <img :src="image.url" :alt="image.photographer" class="fetched-img" >
          </div>  
      </div>
      </div>
    </div>
    <div class="editor-icon-main">
      <div class="editor-icon" @click="toggleOptionsVisibility">
        <i class="icon icon-cross" :class="{ 'rotate-icon': isVisible() }"></i>
      </div>
      <transition name="camera">
        <div class="editor-icon camera-icon" v-if="optionVisible">
          <a-upload
            list-type="picture-card"
            :file-list="fileList"
            name="avatar"
            class="img-upload-btn"
            :before-upload="beforeUpload"
            @change="handleUpload"
          >
            <i class="icon">
              <img src="../../../assets/icons/camera-outline.svg" alt="" />
            </i>
          </a-upload>
        </div>
      </transition>
      <transition name="search">
        <div
          class="editor-icon camera-icon"
          v-if="optionVisible"
          @click="searchUnsplash = true"
        >
          <i class="icon icon-search"></i>
        </div>
      </transition>
      <transition name="video">
        <div class="editor-icon camera-icon" @click="showVideoSearchInput = true" v-if="optionVisible">
          <i class="icon">
            <img src="../../../assets/icons/play-outline.svg" alt="" />
          </i>
        </div>
      </transition>
      <transition name="code">
        <div class="editor-icon camera-icon" @click="embedCode" v-if="optionVisible">
          <i class="icon">
            <img src="../../../assets/icons/code-tags.svg" alt="" />
          </i>
        </div>
      </transition>
      <transition name="new-part">
        <div class="editor-icon camera-icon" v-if="optionVisible" @click="insertHr">
           <i class="icon">
              <img src="../../../assets/icons/minus.svg" alt="" />
            </i>
        </div>
      </transition>
    </div>
    
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
import Tiptap from "./Editor/Tiptap.vue";


export default {
  components: {
    Tiptap,
  },
  mounted(){
    this.active = Tiptap;
  },
  data() {
    return {
      active: null,
      optionVisible: false,
      imageUrl: "",
      file: "",
      searchUnsplash: false,
      searchQuery: null,
      currentPage: 0,
      unsplashImages: [],
      showVideoSearchInput: false,
      videoLink: null,
      video: null,
      shouldinsertHr: false,
      codeLink: null,
      htmlReferences: [],
    };
  },
  watch:{
    currentPage(){
      if(this.$refs['prev-btn'] && this.currentPage==1){
        this.$refs['prev-btn'].disabled=true;
      }
    }
  },
  methods: {
    searchWithUnsplash() {
      this.fetchResults(this.searchQuery.trim(), 1);
    },
    async fetchResults(searchQuery, num) {
      try {
        this.currentPage+=num;
        const results = await this.searchUnsplashfunc(searchQuery);
        console.log(results);
        
        this.displayResults(results);
      } catch (err) {
        console.log(err);
        alert("Failed to search Unsplash");
      }
    },

    async searchUnsplashfunc(searchQuery) {
      const endpoint = `https://api.unsplash.com/search/photos?page=${this.currentPage}&per_page=9&query=${searchQuery}&client_id=-mpCIEMkkDS6Q4bIlX6uDCPeR9zO4tFSCR_iFPeDgzo`;
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      return json;
    },

    displayResults(json) {
        this.unsplashImages = [];
        if(json.total_pages==this.currentPage){
          this.$refs['next-btn'].disabled = true;
        }
        if(this.currentPage==1){
          this.$refs['prev-btn'].disabled = true;
        }
        this.totalResults=json.total;
        json.results.forEach(result => {
        
        let obj = {
          url : result.urls.small,
          photographer : result.user.name,
        }
        this.unsplashImages.push(obj);

         });

      },
    goTo(num){
      this.$refs['prev-btn'].disabled = false;
      this.$refs['next-btn'].disabled = false;
      this.fetchResults(this.searchQuery, num);
    },

    searchVideo(){
      this.$refs['tiptap'].addVideo(this.video);
      this.optionVisible = false;
      this.showVideoSearchInput= false;
      this.video= null;
    },
    toggleOptionsVisibility() {
      this.searchUnsplash = false;
      this.optionVisible = !this.optionVisible;
    },
    isVisible() {
      return this.optionVisible == true;
    },
    beforeUpload(file) {
      this.file = file;
      return false;
    },
    handleUpload() {
      getBase64(this.file, (imageUrl) => {
        this.imageUrl = imageUrl;
        this.$refs['tiptap'].addImage(this.imageUrl);
      });
      // console.log(this.imageUrl);
      // this.$refs['tiptap'].addImage(this.imageUrl);
      this.optionVisible = false;
    },
    selectImage(image){
      this.imageUrl = image;
      this.$refs['tiptap'].addImage(this.imageUrl);
      this.optionVisible = false;
      this.unsplashImages = [];
      this.searchUnsplash = false;
      this.searchQuery = null;
      this.currentPage = 0;
    },
    embedCode(){
      console.log("Embed code");
      // this.showCodeInput = false;
      this.$refs['tiptap'].addcode();
      this.optionVisible = false;
    },
    insertHr(){
      this.$refs['tiptap'].addHr();
    },
  },
};
</script>

<style lang="scss">
@keyframes camera-icon {
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }
  30% {
    opacity: 1;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes search-icon {
  0% {
    opacity: 0;
    transform: translateX(-250%);
  }
  30% {
    opacity: 0;
    transform: translateX(-166%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-83%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes video-icon {
  0% {
    opacity: 0;
    transform: translateX(-350%);
  }
  30% {
    opacity: 0;
    transform: translateX(-233%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-116%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes code-icon {
  0% {
    opacity: 0;
    transform: translateX(-450%);
  }
  30% {
    opacity: 0;
    transform: translateX(-300%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes new-part-icon {
  0% {
    opacity: 0;
    transform: translateX(-550%);
  }
  30% {
    opacity: 0;
    transform: translateX(-366%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-183%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.text-area-main {
  height: 100%;
  .text-area {
    .search-unsplash-input, .search-video-input, .code-input{
      border: none;
      border-bottom: 1px solid $color-dark-grey-2;
      margin-bottom: 2rem;
      padding: 1rem;
      width: 100%;
      font-family: $font-primary;
      color: $color-black;
      font-size: 1.8rem;
      &:focus-visible{
        outline: none;
      }
      &::placeholder{
        font-family: $font-primary;
        opacity: .3;
      }
    }
    .buttons{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn{
        border: none;
        background-color: transparent;
        cursor: pointer;
        opacity: .6;
        &:hover{
          opacity: 1;
        }
      }
    }
    .images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1.8rem;
      margin: 2rem 0;
      .fetched-img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .editor-icon-main {
    display: flex;
    // width: 65%;
    color: $color-dark-grey-1;
    @include respond(phone-x-small) {
      margin-top: 5rem;
    }
    .img-upload-btn {
      //height: 100%;
      .ant-upload-select-picture-card {
        width: 3.8rem !important;
        height: 3.8rem !important;
        border: none !important;
        background-color: transparent !important;
        margin-bottom: 0 !important;
        display: flex;
      }
    }
    .editor-icon {
      border: 0.18rem solid $color-dark-grey-1;
      margin: 1rem 0.9rem;
      width: 3.8rem;
      height: 3.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @include respond(phone-x-small) {
        width: 5rem;
        height: 5rem;
      }
      .rotate-icon {
        transform: rotateZ(90deg) !important;
      }
      .icon-cross {
        transform: rotateZ(45deg);
      }
      .icon {
        transition: 0.2s transform;
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        img {
          width: 1.8rem;
          opacity: .5;
        }
        @include respond(phone-x-small) {
          font-size: 2rem;
        }
      }
    }
    .camera-enter-active {
      animation: camera-icon 0.2s ease-out;
    }
    .camera-leave-active {
      animation: camera-icon 0.2s ease-out reverse;
    }
    .search-enter-active {
      animation: search-icon 0.3s ease-out;
    }
    .search-leave-active {
      animation: search-icon 0.2s ease-out reverse;
    }

    .video-enter-active {
      animation: video-icon 0.4s ease-out;
    }
    .video-leave-active {
      animation: video-icon 0.2s ease-out reverse;
    }

    .code-enter-active {
      animation: code-icon 0.5s ease-out;
    }
    .code-leave-active {
      animation: code-icon 0.2s ease-out reverse;
    }

    .new-part-enter-active {
      animation: new-part-icon 0.6s ease-out;
    }
    .new-part-leave-active {
      animation: new-part-icon 0.2s ease-out reverse;
    }
    .visible {
      display: flex !important;
    }
  }
}
</style>

<template>
  <div class="reference-editor">
    <bubble-menu :editor="editor" v-if="editor">
      <div v-if="showInput" class="options">
        <div class="input-url">
          <a-input
            v-model:value="url"
            type="url"
            name=""
            id="url"
            ref="url"
            placeholder="Paste or write link here"
            :autofocus="autofocus"
            @keyup.enter="setLink()"
          >
            <template #suffix>
              <i class="icon icon-clip"></i>
            </template>
          </a-input>
        </div>
      </div>
    </bubble-menu>
    <editor-content
      :editor="editor"
      @keyup.enter="addReference"
      @click="showInput = true"
    />
  </div>
</template>
<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-3";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Blockquote from "@tiptap/extension-blockquote";

export default {
  components: {
    EditorContent,
    BubbleMenu,
  },
  data() {
    return {
      editor: null,
      url: null,
      showInput: true,
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Blockquote,
        Link.configure({
          openOnClick: false,
        }),
        Placeholder.configure({
          placeholder: "Write reference here",
        }),
      ],
    });
  },
  methods: {
    addReference() {
      let json = this.editor.getJSON();
      console.log(json);
      this.$emit("add-reference", {content: json});
    },
    setLink() {
      if (this.url === null || typeof this.url == "undefined") {
        return;
      }
      // empty
      if (this.url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      // update link
      this.editor
        .chain()
        .extendMarkRange("link")
        .setLink({ href: this.url })
        .blur()
        .run();
      this.url = null;
      this.showInput = false;
    },
  },
};
</script>
<style lang="scss">
.description {
  .bubble-menu {
    .reference-editor {
      .ProseMirror {
        > * + * {
          // margin-top: 0.75em;
        }
      }
      .ProseMirror {
        // margin-bottom: 2rem;
        padding: 1rem !important;
        width: 35rem;
        height: 10rem;
        overflow-y: scroll;
      }
      .ProseMirror-focused {
        border: none;
        outline: none;
      }
      .ProseMirror:first-child {
        font-size: 1.2rem !important;
        font-family: $font-primary;
      }
      .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        font-size: 1.5rem !important;
      }
      p {
        margin-bottom: 0;
        font-size: 1.5rem !important;
      }

      .options {
        box-shadow: 7px 7px 3rem $color-dark-grey-2;
        padding: 0 !important;
        background-color: $color-white;
        &::after {
          content: "";
          width: 2rem;
          height: 2rem;
          background-color: $color-white;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          clip-path: polygon(100% 0, 0 0, 50% 100%);
        }
        .input-url {
          padding: 0;
          width: 40rem !important;
          .ant-input-affix-wrapper {
            border: none;
            &:focus,
            &:focus-visible,
            &:hover {
              border: none;
              outline: none;
            }
            input {
              background-color: $color-white;
              color: $color-black;
            }
            .ant-input-suffix {
              .icon {
                color: $color-primary;
              }
            }
          }
          .ant-input-affix-wrapper-focused {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
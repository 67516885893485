<template>
  <div class="side-tools">
    <div class="tool tool__discover"  @click="$emit('show-research-drawer')">
      <i class="icon icon-discover"></i>
      <p>Research</p>
    </div>
    <div class="tool tool__seo" @click="$emit('show-seo-drawer')">
      <i class="icon icon-search"></i>
      <p>SEO Setup</p>
    </div>
    <div class="tool tool__collaborate"  @click="$emit('show-collaborate-drawer')">
      <i class="icon icon-dollar_circle"></i>
      <p>Collaborate</p>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      /*ShowResearchDrawer: false,
      ShowSeoDrawer: false,*/
    };
  },
};
</script>
<style lang="scss" scoped>
@include reset-css;

.side-tools {
  box-shadow: 0px 0px 19px #00000012;
  padding: 2.2rem;
  width: 8.6rem;
  @include respond(phone-x-small) {
    width: 100%;
    box-shadow: 0px 0px 19px #00000012;
    margin-bottom: 4rem;
    padding: 0.4rem;
    // display: none;
  }
  .tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include respond(phone-x-small) {
      justify-content: center;
      width: 100%;
    }
    cursor: pointer;
    .icon {
      font-size: 2.6rem;
      @include respond(phone-x-small) {
        font-size: 3rem;
      }
    }
    p {
      font-size: 0.8rem;
      font-family: $font-primary-bold;
      margin-top: 0.8rem;
      @include respond(phone-x-small) {
        font-size: 1.7rem;
      }
    }
    &__seo {
      border-bottom: 0.24rem solid #7070701f;
      border-top: 0.24rem solid #7070701f;
      margin: 1.2rem 0rem;
      padding: 1.5rem 0rem;
      @include respond(phone-x-small) {
        border-bottom: none;
        border-top: none;
        border-left: 0.24rem solid #7070701f;
        border-right: 0.24rem solid #7070701f;
      }
    }
  }
}
</style>
